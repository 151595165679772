@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap');

:root {
  /* Color Variable */
  --color-primary: #87472E; /* #0549FA */
  --color-secondary: #9F8266;
  --color-tertiary: #F5F6F8;
  --color-dark: #453638;
  --color-gray: #818F9A;
  --color-light: #E3DBCC;

  /* Font Variable */
  --font-header: 'Mitr', sans-serif;
  --font-default: 'Mitr', sans-serif;

  /* Breakpoint Variable */
  --breakpoint-xxl: '1400px';
  --breakpoint-xl: '1200px';
  --breakpoint-lg: '992px';
  --breakpoint-md: '768px';
  --breakpoint-sm: '576px';
}