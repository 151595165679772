.font-default, 
body, 
h1, h2, h3, h4, h5, h6, 
.h1, .h2, .h3, 
a, p, span{
  font-family: var(--font-default);
}

.font-header{
  font-family: var(--font-header);
}

section{
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}



.btn.btn-white{
  border-radius: 40px;
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: rgba(10, 84, 186, 1);
}
.radius-2{
  border-radius: 2px !important;
}

.btn.btn-theme{
  border-radius: 40px;
  background-color: rgba(10, 84, 186, 1);
  border-color: rgba(10, 84, 186, 1);
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.btn.btn-outline-theme{
  border-radius: 40px;
  background-color: transparent;
  border-color: rgba(10, 84, 186, 1);
  color: rgba(10, 84, 186, 1);
}
.btn-outline-theme-gs{
  border-radius: 40px !important;
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0.2) !important;
  color: rgba(0, 0, 0, 0.4) !important;
}
.btn-outline-theme-black{
  border-radius: 40px !important;
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 1) !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.btn-outline-theme-white{
  border-radius: 40px !important;
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
}
.btn-sharp {
  border-radius: 2px !important;
}

.bg-white {
  background-color: #FFF;
}
.bg-blue {
  background-color: rgba(10, 84, 186, 1);
}
.bg-light {
  background-color: var(--color-light) !important;
}
.bg-dark {
  background-color: rgb(15, 15, 15);
}
.bg-img {
  width: 100%;
  background-color: #EEE;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.ratio-1x1 {
  padding-bottom: 100% !important;
}
.ratio-4x3 {
  padding-bottom: 75% !important;
}
.ratio-16x9 {
  padding-bottom: 56.25% !important;
}
.ratio-21x9 {
  padding-bottom: 42.85% !important;
}
.box-frame{
  border-radius: 15px;
  overflow: hidden;
}
.box-ratio-4x3 {
  height: 75%;
}
.box-ratio-16x9 {
  height: 56.25%;
}
.box-ratio-21x9 {
  height: 42.85%;
}

.form-img{
  border-radius: 15px;
}
.section-form h2, .section-form p, .section-form label {
color: #FFF;
}
.section-form .form-control {
  background-color: transparent !important;
}
.word-cap{
  text-transform: capitalize;
}

/* deco */
.pos-relative{
  position: relative;
}
.deco-box-border {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.6);
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
}
.deco-box-border-theme {
  position: absolute;
  border: 1px solid rgba(10, 84, 186, 0.8);
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
}
.overlay-content {
  position: inherit;
}
.overlay-black{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
}

/* background */
.ibg-primary {
  background-color: var(--color-primary) !important;
}
.ibg-secondary {
  background-color: var(--color-secondary) !important;
}
.ibg-tertiary {
  background-color: var(--color-tertiary) !important;
}
.ibg-dark {
  background-color: var(--color-dark) !important;
}
.ibg-gray {
  background-color: var(--color-gray) !important;
}
.ibg-light {
  background-color: var(--color-light) !important;
}

/* typography */
.ifn-header {
  font-family: var(--font-header);
}
.ifn-default {
  font-family: var(--font-default);
}
.itx-primary {
  color: var(--color-primary) !important;
}
.itx-secondary {
  color: var(--color-secondary) !important;
}
.itx-tertiary {
  color: var(--color-tertiary) !important;
}
.itx-dark {
  color: var(--color-dark) !important;
}
.itx-gray {
  color: var(--color-gray) !important;
}
.itx-light {
  color: var(--color-light) !important;
}
.itx-white, .itx-white a {
  color: #FFF !important;
}

/* border */
.itx-primary {
  border-color: var(--color-primary);
}
.itx-secondary {
  border-color: var(--color-secondary);
}
.itx-tertiary {
  border-color: var(--color-tertiary);
}
.itx-dark {
  border-color: var(--color-dark);
}
.itx-gray {
  border-color: var(--color-gray);
}
.itx-light {
  border-color: var(--color-light);
}

/* overlay */
.iov-container {
  position: relative;
}
.iov-content {
  position: inherit;
}
.iov-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  top: 0;
  left: 0;
}

/* template */
.navbar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.navbar ul li {
  display: inline-block;
}
.navbar-brand .logo{
  width: 8rem;
}
.carousel-caption {
  box-shadow: unset;
  text-align: center !important;
  bottom: calc(50% - 120px) !important;
}
.carousel-caption img {
  width: 10rem;
}
.carousel-caption h1{
  font-family: var(--font-header) !important;
  color: var(--color-dark);
  letter-spacing: 1px;
  font-weight: 800;
}
.carousel-caption h4{
  font-family: var(--font-default) !important;
  color: var(--color-gray);
  font-weight: 300;
}
.btn {
  border-radius: 20px !important;
}
.ibt-primary {
  background-color: var(--color-primary) !important;
  color: #FFF !important;
  font-weight: bold;
}
.ibt-secondary {
  background-color: var(--color-secondary);
  color: #FFF;
  font-weight: bold;
}
.nav-link {
  color: var(--color-dark);
  font-size: 1.4rem !important;
  padding-top: 10px;
}
.top-navi {
  font-size: 1.2rem !important;
}
.top-navi a{
  text-decoration: none;
  color: var(--color-gray);
}
.copyright {
  background-color: var(--color-tertiary);
  color: var(--color-gray);
  font-size: 1rem;
}
.copyright a {
  color: var(--color-gray);
}
.footer {
  background-color: var(--color-tertiary);
  color: var(--color-gray);
  font-size: 1.2rem;
}
.footer a {
  text-decoration: none;
  color: var(--color-gray);
}
.footer h2 {
  font-family: var(--font-header) !important;
  color: var(--color-primary);
  letter-spacing: 1px;
}
.footer h2 a {
  color: var(--color-primary);
}
.footer ul.contact, 
.footer ul.social {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer ul.social li {
  width: 40px;
  height: 40px;
  background-color: var(--color-secondary);
  display: inline-block;
  border-radius: 20px;
  padding: 7px;
  text-align: center;
}
.section-about h5 {
  color: var(--color-primary);
  font-weight: 700;
  font-family: var(--font-header) !important;
  font-size: 2rem;
}
.section-about h5 hr {
  width: 40px;
  display: inline-block;
  margin: 5px 0;
  border: 1px solid var(--color-secondary);
  opacity: 1;
  margin-right: 10px;
}
.section-about h2 {
  color: var(--color-dark);
  font-weight: 500;
  font-family: var(--font-header) !important;
  letter-spacing: 1px;
}
.section-about p {
  color: var(--color-gray);
  font-family: var(--font-default) !important;
  font-size: 18px;
  padding-left: 30px;
}

.section-service h5 {
  color: var(--color-primary);
  font-weight: 700;
  font-family: var(--font-header) !important;
  font-size: 2rem;
}
.section-service h5 hr {
  width: 40px;
  display: inline-block;
  margin: 5px 0;
  border: 1px solid var(--color-secondary);
  opacity: 1;
  margin-right: 10px;
}
.section-service h2 {
  color: var(--color-dark);
  font-weight: 500;
  font-family: var(--font-header) !important;
  letter-spacing: 1px;
}
.section-service p {
  color: var(--color-gray);
  font-family: var(--font-default) !important;
  font-size: 18px;
}

.section-client h5 {
  color: var(--color-primary);
  font-weight: 700;
  font-family: var(--font-header) !important;
  font-size: 2rem;
}

.section-form h5 {
  color: var(--color-light);
  font-weight: 700;
  font-family: var(--font-header) !important;
  font-size: 2rem;
}
.section-form h5 hr {
  width: 40px;
  display: inline-block;
  margin: 5px 0;
  border: 1px solid var(--color-secondary);
  opacity: 1;
  margin-right: 10px;
}
.section-form h2 {
  color: white;
  font-weight: 500;
  font-family: var(--font-header) !important;
  letter-spacing: 1px;
}
.section-form p {
  color: var(--color-light);
  font-family: var(--font-default) !important;
  font-size: 18px;
}
.section-form .form-control {
  background-color: white !important;
}

.section-article h5 {
  color: var(--color-primary);
  font-weight: 700;
  font-family: var(--font-header) !important;
  font-size: 2rem;
}
.section-article h5 hr {
  width: 40px;
  display: inline-block;
  margin: 5px 0;
  border: 1px solid var(--color-secondary);
  opacity: 1;
  margin-right: 10px;
}

.section-faq h5 {
  color: var(--color-primary);
  font-weight: 700;
  font-family: var(--font-header) !important;
  font-size: 2rem;
}
.section-faq h5 hr {
  width: 40px;
  display: inline-block;
  margin: 5px 0;
  border: 1px solid var(--color-secondary);
  opacity: 1;
  margin-right: 10px;
}
.section-faq h2 {
  color: var(--color-dark);
  font-weight: 500;
  font-family: var(--font-header) !important;
  letter-spacing: 1px;
}
.section-faq h6.header {
  color: var(--color-primary);
  font-family: var(--font-header) !important;
  font-size: 1.5rem;
}
.section-faq p {
  color: var(--color-gray);
  font-family: var(--font-default) !important;
  font-size: 18px;
}
.section-faq .faq-filter {
  border-radius: 40px !important;
  padding: 10px 25px;
  border: 1px solid var(--color-secondary);
}

.accordion-item {
  border-bottom: 1px solid var(--color-secondary) !important;
}
.accordion-item:last-child {
  border-bottom: 0 !important;
}
.accordion-button:not(.collapsed) {
  color: var(--color-primary);
  background-color: white;
  font-weight: 500;
  box-shadow: none !important;
  padding-bottom: 0;
}
.accordion-button {
  font-size: 1.4rem;
}
.accordion-body {
  font-size: 1.2rem;
}

.section-contact h5 {
  color: var(--color-light);
  font-weight: 700;
  font-family: var(--font-header) !important;
  font-size: 2rem;
}
.section-contact h5 hr {
  width: 40px;
  display: inline-block;
  margin: 5px 0;
  border: 1px solid var(--color-secondary);
  opacity: 1;
  margin-right: 10px;
}
.section-contact h2 {
  color: white;
  font-weight: 500;
  font-family: var(--font-header) !important;
  letter-spacing: 1px;
}
.section-contact p {
  color: var(--color-light);
  font-family: var(--font-default) !important;
  font-size: 18px;
}

.section-contact ul.contact, 
.section-contact ul.social {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.section-contact ul.contact li {
  color: var(--color-light);
}
.section-contact ul.contact li a {
  text-decoration: none;
  color: var(--color-light);
}
.section-contact ul.social li {
  width: 40px;
  height: 40px;
  background-color: var(--color-secondary);
  display: inline-block;
  border-radius: 20px;
  padding: 7px;
  text-align: center;
}
.service-item {
  padding: 2rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 0rem 1rem;
  cursor: pointer;
}
.service-item h4 {
  font-family: var(--font-header);
  font-size: 1.8rem;
}
.service-item p {
  margin: 0;
  height: 52px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.service-item .img-frame {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-light);
  border-radius: 50%;
  width: 180px;
  padding-bottom: calc(180px - 14px);
  display: inline-block;
  border: 7px solid var(--color-primary);
}
.navbar-corner {
  position: fixed;
  right: 24px;
  bottom: 84px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.navbar-corner li {
  background-color: #FFF;
  color: var(--color-primary);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin-bottom: 5px;
  padding: 5px 0;
  text-align: center;
  border: 3px solid var(--color-primary);
}
.navbar-corner li button {
  background-color: transparent;
  border: 0;
  text-align: center;
}
.navbar-corner li button svg {
  color: var(--color-primary);
}
.logo-ghp {
  max-width: 100%;
}
.tx-modal {
  font-size: 1.5rem;
}
.fs-1-25rem {
  font-size: 1.25rem;
}
.blockquote-footer {
  text-align: right;
  margin-top: 3rem;
  font-size: 1.25rem;
  font-style: italic;
}

/* ict = content */
.ict-container {
  width: 100%;
}
.ict-container p {
  color: var(--color-dark);
}
.ict-container a {
  color: var(--color-primary);
  text-decoration: underline;
}
.ict-container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.ict-gallery {
  width: calc(100% + 1rem);
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  background-color: var(--color-light);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.375rem;
  cursor: pointer;
}

.obj-certify {
  font-family: var(--font-default) !important;
  color: var(--color-dark);
  font-size: 1.6rem;
}
.obj-certify b {
  font-family: var(--font-header);
  color: var(--color-primary);
  display: block;
  font-size: 125%;
  text-shadow: 3px 3px 1px rgba(160, 219, 254, 1);
}

.view-certify {
  background-color: transparent;
  border: 0;
  color: #FFF;
  text-decoration: underline;
}

.client-card {
  background-color: #FFF;
  padding: 20px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
}

.client-card .client-img {
  border-radius: 1rem;
}

.client-card .client-deco {
  position: absolute;
  right: -0.5rem;
  bottom: -0.5rem;
  width: 1.8rem;
}